import React, { useState, useEffect } from "react";
import axios from "axios";
import Common_URL from "../../Config";

const FacilitiesConfig = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updatedHeading, setUpdatedHeading] = useState("");
  const [updatedSubHeading, setUpdatedSubHeading] = useState("");
  const [updatedContact, setUpdatedContact] = useState("");
  const [updatedPdfFile, setUpdatedPdfFile] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [updatedStatus, setUpdatedStatus] = useState(false); // Boolean for status
  const [updatedStartDate, setUpdatedStartDate] = useState(""); // New state
  const [updatedEndDate, setUpdatedEndDate] = useState(""); // New state
  const [updatedContentUrl, setUpdatedContentUrl] = useState(""); // New state for contenturl
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const baseUrl = "https://uservw.online/webapi";

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(Common_URL + "&contenttype=facilities");
        console.log("Fetched Announcements:", response.data);

        // Ensure the 'active' field is correctly interpreted as boolean
        const updatedAnnouncements = response.data.map((announcement) => ({
          ...announcement,
          active: announcement.active === true, // Ensure 'active' is a boolean
        }));

        setAnnouncements(updatedAnnouncements);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleUpdateAnnouncement = async () => {
    try {
      const formData = new FormData();

      // Append required fields
      formData.append("heading", updatedHeading);
      formData.append("subheading", updatedSubHeading);
      formData.append("content", updatedContact);
      formData.append("active", updatedStatus); // Send boolean active status

      // Append date fields
      // formData.append("startdate", updatedStartDate);
      // formData.append("enddate", updatedEndDate);
      formData.append("contenturl", updatedContentUrl); // Include contenturl

      // Append files only if they exist
      if (updatedPdfFile) {
        formData.append("pdfurl", updatedPdfFile);
      }

      if (selectedLogo) {
        formData.append("imageurl", selectedLogo);
      }

      console.log("Form Data being sent:", formData);

      // Send the request
      const response = await axios.put(
        `${baseUrl}/contentlist/${selectedAnnouncementId}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Update response:", response);

      // Update local state if the update was successful
      const updatedAnnouncements = announcements.map((announcement) =>
        announcement.id === selectedAnnouncementId
          ? {
              ...announcement,
              heading: updatedHeading,
              subheading: updatedSubHeading,
              content: updatedContact,
              active: updatedStatus, // Store active as boolean
              // startdate: updatedStartDate,
              // enddate: updatedEndDate,
              contenturl: updatedContentUrl, // Update contenturl
              pdfurl: updatedPdfFile ? URL.createObjectURL(updatedPdfFile) : announcement.pdfurl,
              imageurl: selectedLogo ? URL.createObjectURL(selectedLogo) : announcement.imageurl,
            }
          : announcement
      );
      setAnnouncements(updatedAnnouncements);
      setShowSuccessToast(true);
      setShowUpdateModal(false);
    } catch (error) {
      console.error("Error updating announcement:", error);
      if (error.response) {
        console.error("API Error response:", error.response.data);
      }
      setShowErrorToast(true);
    }
  };

  const handleDownloadPdf = (pdfurl, heading) => {
    if (pdfurl) {
      const link = document.createElement("a");
      link.href = pdfurl;
      link.target = "_blank";
      link.setAttribute("rel", "noopener noreferrer");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(`No PDF available for "${heading}"`);
    }
  };

  const handleDeletePdf = async (announcementId) => {
    try {
      // Show a confirmation dialog
      const confirmDelete = window.confirm("Do you really want to delete this item?");
      
      if (!confirmDelete) {
        // If the user cancels, exit the function
        return;
      }
  
      // Send DELETE request to delete the announcement by ID
      const response = await axios.delete(`${baseUrl}/contentlist/${announcementId}/`);
  
      // If the response is successful, update the state by removing the deleted announcement
      if (response.status === 200) {
        const updatedAnnouncements = announcements.filter(
          (announcement) => announcement.id !== announcementId
        );
        setAnnouncements(updatedAnnouncements);
  
        // Display a success message
        alert("Item deleted successfully!");
        setShowSuccessToast(true);
      }
    } catch (error) {
      console.error("Error deleting announcement:", error);
      setShowErrorToast(true);
  
      // Optionally show an error message to the user
      alert("An error occurred while deleting the item. Please try again.");
    }
  };
  

  const getYearFromDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  const getSessionText = (startDate, endDate) => {
    if (startDate && endDate) {
      return `${getYearFromDate(startDate)} - ${getYearFromDate(endDate)}`;
    } else {
      return "Session Invalid";
    }
  };

  useEffect(() => {
    if (showSuccessToast || showErrorToast) {
      const timeout = setTimeout(() => {
        setShowSuccessToast(false);
        setShowErrorToast(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [showSuccessToast, showErrorToast]);

  const handleRefresh = async () => {
    try {
      const response = await axios.get(Common_URL + "&contenttype=facilities");
      setAnnouncements(response.data);
    } catch (error) {
      console.error("Error refreshing data:", error);
    }
  };

  return (
    <div className="container-fluid px-lg-5">
      <div className="row my-3">
        <div className="col">
          <button className="btn btn-info btn-sm" onClick={handleRefresh}>
            Refresh
          </button>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Heading</th>
            <th scope="col">Content For</th>
            <th scope="col">Sub Heading</th>
            <th scope="col">Content</th>
            <th scope="col">Image Position</th>
            <th scope="col" className="text-right"></th>
          </tr>
        </thead>
        <tbody>
          {announcements.map((announcement) => (
            <tr key={announcement.id}>
              <th>
                <img
                  src={baseUrl + announcement.imageurl}
                  alt="image"
                  width="45"
                  height="40"
                />
              </th>
              <td style={{fontSize:"0.8rem"}}>{announcement.heading}</td>
              <td style={{fontSize:"0.8rem"}}>{announcement.contenturl}</td>
              <td style={{fontSize:"0.8rem"}}>{announcement.subheading}</td>
              <td style={{fontSize:"0.8rem"}}>{announcement.content}</td>
              <td>
                {announcement.active ? (
                  <span className="badge bg-success">Right</span>
                ) : (
                  <span className="badge bg-secondary">Left</span>
                )}
              </td>
              <td>
                <button
                  className="btn btn-sm btn-info d-none"
                  onClick={() =>
                    handleDownloadPdf(
                      announcement.pdfurl,
                      announcement.heading
                    )
                  }
                >
                  Download
                </button>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setSelectedAnnouncementId(announcement.id);
                    setUpdatedHeading(announcement.heading);
                    setUpdatedSubHeading(announcement.subheading);
                    setUpdatedContact(announcement.content);
                    setUpdatedStatus(announcement.active); // Ensure status is boolean
                    // setUpdatedStartDate(announcement.startdate); // Set startdate
                    // setUpdatedEndDate(announcement.enddate); // Set enddate
                    setUpdatedContentUrl(announcement.contenturl); // Set contenturl
                    // setSelectedLogo(announcement.imageurl);
                    setShowUpdateModal(true);
                  }}
                >
                  Update
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleDeletePdf(announcement.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal */}
      {showUpdateModal && (
        
        
          <>
            {/* Modal Backdrop */}
            <div className="modal-backdrop fade show"></div>
        
            {/* Modal */}
            <div className="modal fade show" style={{ display: "block" }}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Update Facilities Data</h5>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => setShowUpdateModal(false)}
                    ></button>
                  </div>
                  <div className="modal-body">
                    {/* Fields */}
                    <div className="mb-3">
                      <label className="form-label">Heading</label>
                      <input
                        type="text"
                        className="form-control"
                        value={updatedHeading}
                        onChange={(e) => setUpdatedHeading(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Sub Heading</label>
                      <input
                        type="text"
                        className="form-control"
                        value={updatedSubHeading}
                        onChange={(e) => setUpdatedSubHeading(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Content</label>
                      <textarea
                      rows={5}
                        className="form-control"
                        value={updatedContact}
                        onChange={(e) => setUpdatedContact(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Image Position</label>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={updatedStatus}
                          onChange={(e) => setUpdatedStatus(e.target.checked)} // Set as boolean
                        />
                        <label className="form-check-label">Right</label>
                      </div>
                    </div>
        
                    <div className="mb-3 d-none">
                      <label className="form-label">Start Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={updatedStartDate}
                        onChange={(e) => setUpdatedStartDate(e.target.value)}
                      />
                    </div>
                    <div className="mb-3 d-none">
                      <label className="form-label">End Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={updatedEndDate}
                        onChange={(e) => setUpdatedEndDate(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Content URL</label>
                      <input
                        type="url"
                        className="form-control"
                        value={updatedContentUrl}
                        onChange={(e) => setUpdatedContentUrl(e.target.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Update Image</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setSelectedLogo(e.target.files[0])}
                      />
                    </div>
                    <div className="mb-3 d-none">
                      <label className="form-label">Update PDF</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setUpdatedPdfFile(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setShowUpdateModal(false)}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleUpdateAnnouncement}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        
      
    </div>
  );
};


export default FacilitiesConfig;

import React, { useState, useEffect } from "react";
import axios from "axios";
import pdf2 from "../../assets/illustration/pdf2.png"; // Import the PDF icon image
import Common_URL from "../../Config";
const Mpd = () => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(Common_URL + "&contenttype=mandetory");
        setAnnouncements(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleDownloadPdf = (pdfUrl, heading) => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.setAttribute("rel", "noopener noreferrer"); // For security
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(`No PDF available for "${heading}"`);
    }
  };
  const baseUrl = "https://uservw.online/webapi";

  return (
    <div>
      {announcements.map((announcement) => (
        <div>
          <div
            className="d-flex justify-content-between my-2 align-items-center"
            key={announcement.id}
          >
            <span>
              <img src={pdf2} alt="PDF" width="45" height="40" />
              {/* Display the PDF icon */}
              <span className="mpd_p">{announcement.heading}</span>
            </span>
            {announcement.pdfurl && (
              <button
                className="btn btn-sm btn-danger"
                onClick={() =>
                  handleDownloadPdf(
                    baseUrl + announcement.pdfurl,
                    announcement.heading
                  )
                }
              >
                Download PDF
              </button>
            )}
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default Mpd;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/about-section.css";
import about from "../../assets/all-images/about.png";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import logo from "../../assets/images/logo.png";
import footerSchool from "../../assets/all-images/school_img/footerSchool.png";
import Common_URL from "../.././Config";

const quickLinks = [
  { path: "/about", display: "About" },
  { path: "#", display: "Privacy Policy" },
  { path: "/gallery", display: "Gallery" },
  { path: "/blogs", display: "Blog" },
  { path: "/contact", display: "Contact" },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const platforms = ["facebook", "twitter", "linkedin", "youtube", "whatsapp"];
        const responses = await Promise.all(
          platforms.map((platform) =>
            axios
              .get(`${Common_URL}&contenttype=${platform}`)
              .then((res) => ({
                platform,
                data: res.data[0]?.contenturl || null, // Handle missing contenturl gracefully
              }))
              .catch(() => ({ platform, data: null })) // Gracefully handle failed requests
          )
        );

        // Filter out platforms with empty data
        const validContents = responses.filter((response) => response.data !== null);
        setContents(validContents);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchData();
  }, []);

  const renderContents = () => {
    if (contents.length === 0) return null; // Render nothing if no valid platforms are available

    return contents.map((item, index) => (
      <li key={index}>
        {item.platform.toLowerCase() === "whatsapp" ? (
          <a
            href={`https://wa.me/${item.data}`}
            target="_blank"
            rel="noopener noreferrer"
            className="social__link-icon whatsapp-link"
          >
            <i className={`fab fa-${item.platform.toLowerCase()}`}></i>
          </a>
        ) : (
          <a
            href={item.data}
            target="_blank"
            rel="noopener noreferrer"
            className="social__link-icon"
          >
            <i className={`fab fa-${item.platform.toLowerCase()}`}></i>
          </a>
        )}
      </li>
    ));
  };

  return (
    <>
      <footer className="footer">
        <div className="solar_footer">
          <div className="container">
            <div className="solar_footer_shape">
              <div className="solar_footer_gradient"></div>
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  <div className="solar_footer_ab">
                    <div className="text-center">
                      <img
                        src={logo}
                        alt=""
                        className="img_fluid footer_logo"
                      />
                      <p style={{ zoom: "0.74" }}>
                        Specializing in children’s art classes, camps and art-themed birthday
                        parties, Kidcreate Studio is an art studio just for kids. At Kidcreate, your
                        child will create fridge-worthy masterpieces, learn art concepts, and
                        experiment with many different art materials.
                      </p>
                    </div>
                    <hr />
                    <form className="center mt-4">
                      <input placeholder="email@example.com" />
                      <button type="button">Get In Touch!</button>
                    </form>
                  </div>
                </div>

                <div className="col-lg-2 col-md-12" style={{ zoom: "0.8" }}>
                  <div className="solar_footer_contact">
                    <h3>Useful Links</h3>
                    <div className="solar_links">
                      <ListGroup>
                        {quickLinks.map((item, index) => (
                          <ListGroupItem key={index} className="mt-0 quick__link">
                            <i className="fa fa-angle-double-right" aria-hidden="true"></i>{" "}
                            <Link to={item.path}>{item.display}</Link>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-12" style={{ zoom: "0.76" }}>
                  <div className="solar_footer_contact">
                    <div className="solar_information">
                      <ul>
                        <h3>Contact Us</h3>
                        <li>
                          <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                          Golapar Barbigha, Sheikhpura, 811101
                        </li>
                        <li>
                          <i className="fa fa-globe" aria-hidden="true"></i>{" "}
                          https://goldeneraenglishschool.com/
                        </li>
                        <li>
                          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                          info@goldeneraenglishschool.com
                        </li>
                        <li>
                          <i className="fa fa-phone" aria-hidden="true"></i> +91 9570250616,
                          7321860859
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="top_solar_btn text-right">
                    <ul>{renderContents()}</ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="solar_copyright d-flex justify-content-between">
              <p>Copyright © {year} Golden Era All Rights Reserved</p>
              <p>
                Developed by{" "}
                <a href="https://wa.me/7549595198" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-whatsapp"></i>{" "}
                  <span className="text_warning">VA TECHNOLOGIES</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

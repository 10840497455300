import { createGlobalStyle } from "styled-components";
import * as theme from "./Theme.styled";
export const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.filter-buttons .btn-active{
background-color: ${({ theme }) => theme.colors.background};
}
.filter-buttons .btn:hover{
color: ${({ theme }) => theme.colors.background} !important;
}
body {
  background-color: ${({ theme }) => theme.colors.background};
  // background: #fff !important;
  overflow-x: hidden;
  font-weight:300;
}
.main_check{
position:absolute;
bottom:0;
z-index:-99 !important;
width:0.01px;
display:none !important;
}

// theme buttons color
.defaults {
  background-color: ${theme.defaults.colors.background};
}
.dark {
  background-color: ${theme.dark.colors.background};
}
.red {
  background-color: ${theme.red.colors.background};
}
.green {
  background-color: ${theme.green.colors.background};
}
.brown {
  background-color: ${theme.brown.colors.background};
}
.pink {
  background-color: ${theme.pink.colors.background};
}
// new theme added
.colorA {
  background-color: ${theme.colorA.colors.background};
}
.colorB {
  background-color: ${theme.colorB.colors.background};
}
.colorC {
  background-color: ${theme.colorC.colors.background};
}
.colorD {
  background-color: ${theme.colorD.colors.background};
}
.colorE {
  background-color: ${theme.colorE.colors.background};
}
.colorF {
  background-color: ${theme.colorF.colors.background};
}
.colorG {
  background-color: ${theme.colorG.colors.background};
}
.colorH {
  background-color: ${theme.colorH.colors.background};
}
.colorI {
  background-color: ${theme.colorI.colors.background};
}
.colorJ {
  background-color: ${theme.colorJ.colors.background};
}
.nav__active{
  text-shadow: 0px 0.5px 3px rgb(0 0 0 / 8%), 0px 4px 8px rgb(0 0 0 / 13%), 0px 6px 12px rgb(0 0 0 / 12%);
color: ${({ theme }) => theme.colors.color} !important;
}
.header__top, .menu_row, .topbar {
  background-color: ${({ theme }) => theme.colors.background} !important;
}
.section__title{
  color: ${({ theme }) => theme.colors.background} !important;
}
  .div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  right: 0%;
  width: 90%;
  height: 0.5px;
  background-image: linear-gradient(to right, transparent, ${({ theme }) => theme.colors.background}, transparent);
}
.solar_center{
  text-align:center;
}
h2.text_span {
background:${({ theme }) => theme.colors.background};
}
.solar_footer {
  // background-image: linear-gradient(to right, ${({ theme }) => theme.colors.background}, ${({ theme }) => theme.colors.background});
  background: #83a4d4;
background: -webkit-linear-gradient(to top, #b6fbff, #83a4d4); 
background: linear-gradient(to top, #b6fbff, #83a4d4);
}
.Admin .nav-pills .nav-link.active{
  background-color: ${({ theme }) => theme.colors.background} !important;
  border-radius:25px !important;
  color:white !important;
}
.Admin .nav-pills .nav-link{
  color: ${({ theme }) => theme.colors.background} !important;
border: 1px solid ${({ theme }) => theme.colors.background} !important;
border-radius:25px !important;
margin:5px;
text-transform:capitalize;
}
`;

import React, { useState, useEffect } from "react";
import axios from "axios";
import pdf2 from "../../assets/illustration/pdf2.png"; // Import the PDF icon image
import Common_URL from "../../Config";
const ExamNoticeAdmin = () => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(Common_URL + "&contenttype=other1");
        setAnnouncements(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleDownloadPdf = (imageurl, heading) => {
    if (imageurl) {
      const link = document.createElement("a");
      link.href = imageurl;
      link.target = "_blank";
      link.setAttribute("rel", "noopener noreferrer"); // For security
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(`No PDF available for "${heading}"`);
    }
  };
  const baseUrl = "https://uservw.online/webapi";

  const getYearFromDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  const getSessionText = (startDate, endDate) => {
    if (startDate && endDate) {
      return `${getYearFromDate(startDate)} - ${getYearFromDate(endDate)}`;
    } else {
      return "Session Invalid";
    }
  };

  return (
    <div>
      <div className="exam_notice_main">
        <div className="row d-flex justify-content-center">
          {announcements.map((announcement) => (
            <div className="col-11" key={announcement.id}>
              <div className="p-2">
                <p className="d-flex justify-content-between mb-0">
                  {announcement.heading}{" "}
                  <span>
                    Session :{" "}
                    {getSessionText(
                      announcement.startdate,
                      announcement.enddate
                    )}
                  </span>
                </p>
                <hr />
              </div>
              <img
                src={baseUrl + announcement.imageurl}
                alt="PDF"
                className="exam_notice"
              />

              <div className="mt-3">
                {announcement.imageurl && (
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      handleDownloadPdf(
                        baseUrl + announcement.imageurl,
                        announcement.heading
                      )
                    }
                  >
                    Download
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamNoticeAdmin;

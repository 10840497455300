import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Common_URL from "../../Config";

const HeroSlider = () => {
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Common_URL + "&contenttype=slider");
        const data = await response.json();
        setContentList(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000, // Adjust fade animation speed
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    fade: true, // Enable fade animation
    pauseOnHover: true, // Prevent pause on hover
  };

  const baseUrl = "https://uservw.online/webapi";

  return (
    <div className="slide_cloud">
      <Slider {...settings}>
        {contentList.map((content, index) => (
          <div className="px-0 w-100">
            <div key={index} className="slide-item">
              <img src={baseUrl + content.imageurl} alt={content.heading} />
            </div>
            <div className="static_content">
              <h2 className="mb-1" style={{ color: content.contenturl || "#000" }}>{content.heading}</h2>
              <h4 style={{ color: content.contenturl || "#000" }}>
                {content.subheading}
              </h4>
              <p style={{ color: content.contenturl || "#000" }}>{content.content}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HeroSlider;

import React, { useState, useEffect } from "react";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const LoginPage = () => {
  const [loginId, setLoginId] = useState(""); // Initial state is empty
  const [password, setPassword] = useState(""); // Initial state is empty
  const [isChecked, setIsChecked] = useState(false); // Checkbox state
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false); // State for submit button
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    if (isAuthenticated === "true" || !isAuthenticated) {
      setIsSubmitDisabled(true); // Disable submit button if not authenticated
    }
  }, []);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (loginId === "admin" && password === "prince@") {
  //     console.log("Login successful!");
  //     navigate("/adminlayout"); // Redirect to admin layout on successful login
  //     setTimeout(() => {
  //       localStorage.setItem("isAuthenticated", "false"); // Set isAuthenticated to false after 5 seconds
  //     }, 5000);
  //   } else {
  //     console.log("Invalid login credentials.");
  //   }
  // };


  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (loginId === "goldenera" && password === "goldi@2025") {
      console.log("Login successful!");
      navigate("/adminlayout");
    } else {
      console.log("Invalid login credentials.");
    }
  };

  return (
    <>
      <section className="container-fluid">
        <section className="loginwrapper">
          <div className="container">
            <div id="scene" className="scene" data-hover-only="false">
              <div className="circle" data-depth="1.2"></div>
              <div className="circle2" data-depth="1.2"></div>
              <div className="one" data-depth="0.9">
                <div className="content">
                  <span className="piece"></span>
                  <span className="piece"></span>
                  <span className="piece"></span>
                </div>
              </div>
              <div className="two" data-depth="0.60">
                <div className="content">
                  <span className="piece"></span>
                  <span className="piece"></span>
                  <span className="piece"></span>
                </div>
              </div>
              <div className="three" data-depth="0.40">
                <div className="content">
                  <span className="piece"></span>
                  <span className="piece"></span>
                  <span className="piece"></span>
                </div>
              </div>
              <Link
                className="p404"
                data-depth="0.50"
                to="#"
                onClick={() => window.history.back()}
              >
                <i className="fas fa-long-arrow-alt-left mt-2"></i> Go Back
              </Link>
            </div>
            <div className="text">
              <article>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="loginId" className="form-label">
                      Login ID:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="loginId"
                      placeholder="Enter your login ID"
                      autoComplete="off"
                      value={loginId}
                      onChange={(e) => setLoginId(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter your password"
                      autoComplete="new-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      // disabled={isSubmitDisabled}
                    >
                      Login
                    </button>
                  </div>
                </form>
              </article>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default LoginPage;
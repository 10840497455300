// import React from "react";
// import YoutubeClassMain from "./YoutubeClassMain";
// import CommonSection from "../components/UI/CommonSection";

// const YoutubeClass = () => {
//   return (
//     <>
//      <CommonSection title="Youtube Classes" />
//       <div className="youtube_player">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-6">
//               <YoutubeClassMain youtubeId="32LWNnkAzNs" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default YoutubeClass;




import React, { useState, useEffect } from "react";
import YoutubeClassMain from "./YoutubeClassMain";
import CommonSection from "../components/UI/CommonSection";

import axios from "axios";
import { Link } from "react-router-dom";
import "../styles/about-section.css";
import Common_URL from "../Config";

const YoutubeClass = ({ aboutClass }) => {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Common_URL + "&contenttype=youtube"); // Fetch all relevant content
        setContents(response.data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };
    fetchData();
  }, []);

  const renderContents = () => {
    const baseUrl = "https://uservw.online/webapi";

    return contents
      .filter((content) => content.heading === "youtubeclass")
      .map((content, index) => (
        <div className="col-md-6" key={index}>
          <YoutubeClassMain youtubeId={content.contenturl} />
        </div>
      ));
  };

  return (
    <>
      <CommonSection title="Youtube Classes" />
      <div className="youtube_player">
        <div className="container">
          <div className="row">
            {renderContents()}
          </div>
        </div>
      </div>
    </>
  );
};

export default YoutubeClass;

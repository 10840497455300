import React, { Component, createRef } from "react";
import "../styles/YoutubeClass.css"; // Assuming you have relevant CSS

class YoutubeClassMain extends Component {
  constructor(props) {
    super(props);

    this.videoRef = createRef();
    this.progressBarRef = createRef();
    this.bufferBarRef = createRef();
    this.volumeWrapperRef = createRef();
    this.volumeBarRef = createRef();

    this.state = {
      playing: false,
      muted: false,
      volume: 0.7,
      currentTime: 0,
      duration: 0,
      playbackRate: 1,
      bufferWidth: 0,
      progressWidth: 0,
    };
  }

  componentDidMount() {
    this.loadYouTubeAPI();
  }

  loadYouTubeAPI = () => {
    const script = document.createElement("script");
    script.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(script);
    window.onYouTubeIframeAPIReady = this.initializePlayer;
  };

  initializePlayer = () => {
    const { youtubeId } = this.props;

    this.player = new window.YT.Player(this.videoRef.current, {
      videoId: youtubeId,
      events: {
        onReady: this.handlePlayerReady,
        onStateChange: this.handlePlayerStateChange,
        onPlaybackQualityChange: this.handlePlaybackQualityChange,
      },
    });
  };

  handlePlayerReady = () => {
    this.setState({ duration: this.player.getDuration() });
  };

  handlePlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      this.setState({ playing: true });
      this.updateCurrentTime();
    } else if (event.data === window.YT.PlayerState.PAUSED) {
      this.setState({ playing: false });
    }
  };

  handlePlaybackQualityChange = (event) => {
    // Optional: handle changes in video quality if needed
  };

  updateCurrentTime = () => {
    if (this.player) {
      this.setState({
        currentTime: this.player.getCurrentTime(),
        progressWidth: (this.player.getCurrentTime() / this.state.duration) * 100,
      });
      setTimeout(this.updateCurrentTime, 1000); // Update every second
    }
  };

  togglePlay = () => {
    if (this.state.playing) {
      this.player.pauseVideo();
    } else {
      this.player.playVideo();
    }
  };

  toggleMute = () => {
    const muted = !this.state.muted;
    this.player.mute();
    if (!muted) {
      this.player.unMute();
    }
    this.setState({ muted });
  };

  changeVolume = (e) => {
    const volumeWrapper = this.volumeWrapperRef.current;
    const position = e.pageX - volumeWrapper.getBoundingClientRect().left;
    const volume = Math.max(0, Math.min(1, position / volumeWrapper.offsetWidth));
    this.player.setVolume(volume * 100);
    this.setState({ volume });
  };

  changeSpeed = () => {
    const playbackRates = [1, 1.25, 1.5, 1.75, 2];
    const currentRateIndex = playbackRates.indexOf(this.state.playbackRate);
    const nextRate = playbackRates[(currentRateIndex + 1) % playbackRates.length];
    this.player.setPlaybackRate(nextRate);
    this.setState({ playbackRate: nextRate });
  };

  launchFullscreen = () => {
    const iframe = this.videoRef.current.querySelector("iframe");  // Targeting iframe inside the container
    console.log(iframe); // Check to ensure iframe is being selected properly
  
    if (iframe) {
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      } else if (iframe.mozRequestFullScreen) { // Firefox
        iframe.mozRequestFullScreen();
      } else if (iframe.webkitRequestFullscreen) { // Chrome, Safari, Opera
        iframe.webkitRequestFullscreen();
      } else if (iframe.msRequestFullscreen) { // Internet Explorer/Edge
        iframe.msRequestFullscreen();
      }
    } else {
      console.error("Iframe not found for fullscreen.");
    }
  };
  

  seek = (e) => {
    const progressBar = this.progressBarRef.current;
    const position = e.pageX - progressBar.getBoundingClientRect().left;
    const percentage = Math.max(0, Math.min(1, position / progressBar.offsetWidth));
    this.player.seekTo(percentage * this.state.duration);
    this.setState({ progressWidth: percentage * 100 });
  };

  formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60).toString().padStart(2, "0");
    const secs = Math.floor(seconds % 60).toString().padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  render() {
    const {
      playing,
      muted,
      volume,
      currentTime,
      duration,
      playbackRate,
      bufferWidth,
      progressWidth,
    } = this.state;
    const { youtubeId } = this.props;

    return (
     
           <>
           {/* <div className="row">
              <div className="col-md-6"> */}
              <div className="box text-center" dir="ltr">
                <div className="neo-video-player" id="popout-video-player">
                  <div className="video-element-wrapper">
                  <div className="iframe-container" ref={this.videoRef} id="video-element">
                  <iframe
                    title="YouTube Video"
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${youtubeId}?enablejsapi=1&modestbranding=1&rel=0&controls=1&autoplay=0`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>

                  </div>
                  </div>

                  <div className="video-control-part">
                    <div className="video-control-general-part">
                      <div
                        className={`play-btn video-neu-btn ${
                          playing ? "pause" : ""
                        }`}
                        onClick={this.togglePlay}
                      ></div>
                      <div className="video-neu-btn drop-btn">
                        <div className="box-sound">
                          <div
                            className="volume"
                            ref={this.volumeWrapperRef}
                            onMouseDown={this.changeVolume}
                          >
                            <div
                              className="bar-volume"
                              style={{ width: `${volume * 100}%` }}
                            ></div>
                          </div>
                        </div>
                        <div
                        className={`sound-btn video-neu-btn ${muted ? "mute" : ""}`} // Add the conditional class here
                        onClick={this.toggleMute}
                      ></div>
                      </div>
                    </div>
                    <div className="video-control-bar-part">
                      <div className="bar-bg">
                        <div
                          className="bar-progress"
                          ref={this.progressBarRef}
                          onClick={this.seek}
                        >
                          <div
                            className="bar-time"
                            style={{ width: `${progressWidth}%` }}
                          >
                            <div className="bar-time-box">
                              <span className="current">
                                {this.formatTime(currentTime)}
                              </span>
                              <span> / </span>
                              <span className="duration">
                                {this.formatTime(duration)}
                              </span>
                            </div>
                            <div className="bar-pin"></div>
                          </div>
                        </div>
                        <div
                          className="bar-buffer"
                          style={{ width: `${bufferWidth}%` }}
                        ></div>
                      </div>
                    </div>
                    <div className="video-control-nav-part">
                    <a id="download-video" href={this.props.src} rel="nofollow" download>
                      <div className="download-btn video-neu-btn"></div>
                    </a>
                      <div className="setting-btn video-neu-btn">
                        <div className="fastFwd-box">
                          <input
                            className="btn fastFwd"
                            type="button"
                            value={`Speed : ${playbackRate}x`}
                            onClick={this.changeSpeed}
                          />
                        </div>
                      </div>
                      <a
                        id="download-video"
                        href={`https://www.youtube.com/watch?v=${youtubeId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <div  className="fullscreen-btn video-neu-btn"></div>
                      </a>
                      
                    </div>
                  </div>
                </div>
              </div>
              {/* </div>
              </div> */}
           </>
           
    );
  }
}

export default YoutubeClassMain;

import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Facilities from "../pages/Facilities";
import CarDetails from "../pages/CarDetails";
import Gallery from "../pages/Gallery";
import BlogDetails from "../pages/BlogDetails";
import NewsEvents from "../pages/NewsEvents";
import AcademicCalendar from "../pages/AcademicCalendar";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import MandatoryPublicDisclosure from "../pages/MandatoryPublicDisclosure";
import LoginPage from "../pages/LoginPage/LoginPage";
import AdminMainLayout from "../pages/Admin/AdminMainLayout";
import FeeStr from "../pages/FeeStr";
import Syllabus from "../pages/Syllabus";
import ExaminationNotice from "../pages/ExaminationNotice";
import YoutubeClass from "../pages/YoutubeClass";
import Curriculam from "../components/UI/Curriculam";
import BirthdayCleb from "../components/UI/BirthdayCleb";
import HolidayHomeWork from "../pages/HolidayHomeWork";

// Initialize localStorage with default value
const initializeAuth = () => {
  console.log("working");
  if (localStorage.getItem("isAuthenticated") === null) {
    localStorage.setItem("isAuthenticated", "true");
  }
};

// Helper to get authentication status from localStorage
const isAuthenticated = () => {
  return localStorage.getItem("isAuthenticated") === "true";
};

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    // Redirect unauthenticated users to the login page
    return <Navigate to="/login" replace />;
  }
  return children;
};

const Routers = () => {
  useEffect(() => {
    initializeAuth(); // Ensure localStorage is initialized on app start
  }, []);

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/facilities" element={<Facilities />} />
      <Route path="/cars/:slug" element={<CarDetails />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/holidayhomwork" element={<HolidayHomeWork />} />
      
      <Route path="/newsevents" element={<NewsEvents />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route
        path="/MandatoryPublicDisclosure"
        element={<MandatoryPublicDisclosure />}
      />
      <Route path="/feestructure" element={<FeeStr />} />
      <Route path="/syllabus" element={<Syllabus />} />
      <Route path="/examinationnotice" element={<ExaminationNotice />} />
      <Route path="/academicalendar" element={<AcademicCalendar />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/youtubeclass" element={<YoutubeClass />} />
      <Route path="/curriculam" element={<Curriculam />} />
      <Route path="/birthday-celebration" element={<BirthdayCleb />} />

      {/* Protected Routes */}
      <Route
        path="/adminlayout"
        element={
          <ProtectedRoute>
            <AdminMainLayout />
          </ProtectedRoute>
        }
      />

      {/* Catch-All Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;

import React, { useRef, useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "../../styles/header.css";
import axios from "axios";
import Common_URL from "../.././Config";

const navLinks = [
  { path: "/home", display: "Home" },
  { path: "/about", display: "About Us" },
  { path: "/facilities", display: "Facilities" },
  { path: "/birthday-celebration", display: "Birthday Celebration" },
  { path: "/curriculam", display: "Curriculam" },
  { path: "/gallery", display: "Gallery" },
  { path: "/newsevents", display: "News & Events" },
  { path: "/contact", display: "Contact Us" },
  { path: "/youtubeclass", display: "Youtube Classes" },
];

const Header = () => {
  const [navClass, setNavClass] = useState("");
  const [contents, setContents] = useState([]); // Stores only valid platforms
  const [contentList, setContentList] = useState([]); // For marquee news

  // Fetch social media platform links
  useEffect(() => {
    const fetchData = async () => {
      try {
        const platforms = ["facebook", "twitter", "linkedin", "youtube", "whatsapp"];
        const responses = await Promise.all(
          platforms.map((platform) =>
            axios
              .get(`${Common_URL}&contenttype=${platform}`)
              .then((res) => ({
                platform,
                data: res.data[0]?.contenturl || null, // Handle missing contenturl gracefully
              }))
              .catch(() => ({ platform, data: null })) // Gracefully handle failed requests
          )
        );

        // Filter out platforms with empty data
        const validContents = responses.filter((response) => response.data !== null);
        setContents(validContents); // Store only valid platform data
      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };

    fetchData();
  }, []);

  // Fetch marquee news
  useEffect(() => {
    const fetchContentList = async () => {
      try {
        const response = await fetch(`${Common_URL}&contenttype=upcoming%20news`);
        const data = await response.json();
        setContentList(data);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchContentList();
  }, []);

  // Render social media links
  const renderContents = () => {
    if (contents.length === 0) {
      return null; // Render nothing if no valid platforms are available
    }

    return contents.map((item, index) => (
      <li key={index}>
        {item.platform === "whatsapp" ? (
          <a
            href={`https://wa.me/${item.data}`}
            className="social__link-icon whatsapp-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp"></i>
          </a>
        ) : (
          <a
            href={item.data}
            className="social__link-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={`fab fa-${item.platform.toLowerCase()}`}></i>
          </a>
        )}
      </li>
    ));
  };

  const menuRef = useRef(null);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  // Handle scroll for navbar
  useEffect(() => {
    const onScroll = () => {
      setNavClass(window.scrollY >= 200 ? "scrolled" : "");
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header className="header">
      <div className="header__top">
        <div className="container-fluid">
          <Row>
            <Col lg="12" md="12" sm="12">
              <div className="topbar">
                <div className="d-flex align-item-center justify-content-between">
                  <div className="header__top__left col-md-12">
                    <marquee
                      className="news-scroll"
                      behavior="scroll"
                      direction="left"
                      scrollamount="5"
                      scrolldelay="50"
                    >
                      {contentList.map((content, index) => (
                        <span key={index} className="topbar_news">
                          <span className="dot"></span>{" "}
                          <span>{content.heading}... &nbsp;</span>
                          <Link to="/newsevents" className="text-danger">
                            Read More
                          </Link>
                        </span>
                      ))}
                    </marquee>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="main__navbars">
        <div className="container-fluid px-lg-5">
          <Row className="d-flex align-items-center">
            <Col lg="2" md="3" sm="4">
              <div className="logo">
                <Link to="/home" className="d-flex align-items-center gap-2">
                  <img src={logo} alt="Logo" className="img-fluid" />
                </Link>
              </div>
            </Col>
            <Col lg="10" md="9" sm="8">
              <div className="d-flex justify-content-end align-items-center">
                <span className="header__top__help mr-4">
                  <i className="ri-mail-fill"></i> info@goldeneraenglishschool.com
                </span>
                <span className="header__top__help ml-5">
                  <i className="ri-phone-fill"></i> +91 9570250616, 7321860859
                </span>

                <div className="header__top__right d-flex align-items-center gap-2">
                  <ul className="header_social_icon">{renderContents()}</ul>
                  <Link
                    to="/loginpage"
                    className="d-none align-items-center gap-2 login_btn"
                  >
                    <i className="ri-login-circle-line"></i> Login
                  </Link>
                </div>
              </div>

              <Row
                className={`d-flex align-items-center menu_row mt-2 main__navbar ${navClass}`}
              >
                <Col lg="12">
                  <div className="navigation__wrapper d-flex align-items-center justify-content-center">
                    <span className="mobile__menu" onClick={toggleMenu}>
                      <i className="ri-menu-line"></i>
                    </span>
                    <div className="navigation" ref={menuRef}>
                      <div className="menu">
                        {navLinks.map((item, index) => (
                          <NavLink
                            to={item.path}
                            className={(navClass) =>
                              navClass.isActive
                                ? "nav__active nav__item"
                                : "nav__item"
                            }
                            key={index}
                          >
                            {item.display}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";

import driveImg from "../assets/all-images/drive.jpg";
import OurToppers from "../components/UI/OurToppers";
import "../styles/about.css";
import AboutUsPage from "../components/UI/AboutUsPage";

const About = () => {
  return (
    <>
      <Helmet title="About">
        <CommonSection title="About Us" />
        <AboutUsPage aboutClass="aboutPage" />

        <section className="about__page-section p-0">
          <Container>
            <Row>
             
              <Col lg="12" md="12" sm="12">
                <div className="about__page-content">
                  <div class="solar_our_vison_detail">
                    <h5 class="solar_center solar_left">
                      What make us different
                    </h5>
                    <h1 class="text_span">Why choose us</h1>
                    <p>
                      Great schools have many distinguishing features in common.
                      Some exhibit a number of uncommon features as well. This
                      post is not intended to be an exhaustive report on all the
                      characteristics of great schools. However these
                      features that most educational experts and practitioners
                      consider to be necessary in the anatomy of any great
                      school. I present them here in no particular order.
                    </p>
                  </div>

                  <div class="row">
                    <div className="col-12">
                      <div className="banner_points">
                        <article style={{ "--slist": "#ebac79, #d65b56" }}>
                          <p>
                            A very clear and shared mission and vision for the
                            school. A contextual philosophy of education for the
                            school is also paramount. Staff, students, parents,
                            and the community need to know the purpose and
                            operational parameters of the school. Nothing is
                            left to chance.
                          </p>
                        </article>
                        <article style={{ "--slist": "#90cbb7, #2fb1a9" }}>
                          <p>
                            Very clear goals and objectives which are
                            communicated to the staff, students, parents, and
                            the wider community. Everyone in the school knows
                            exactly what they are required to do daily to meet
                            the institutional goals and objectives. This speaks
                            to performance on every level.
                          </p>
                        </article>
                        <article style={{ "--slist": "#8a7876, #32201c" }}>
                          <p>
                            Great leadership from the principal. The principal
                            is both an instructional leader and manager of the
                            school. While both these roles are important,
                            attention to instructional leadership drives
                            continual improvement in teaching and learning
                            across the school.
                          </p>
                        </article>
                        <article style={{ "--slist": "#a6c869, #37a65a" }}>
                          <p>
                            A relentless focus on teaching and learning is
                            informed by the belief that all students can learn.
                            Teachers find innovative ways to motivate and engage
                            students. The curriculum is rigorous, and teacher
                            performance is regularly monitored to ensure
                            academic and extracurricular excellence.
                          </p>
                        </article>
                        <article style={{ "--slist": "#d1a5e3, #8c5ac8" }}>
                          <p>
                            A collaborative approach to school management
                            fosters teamwork among staff and students. Active
                            engagement with the community ensures sustainability
                            and growth. Shared leadership empowers all
                            stakeholders, promoting accountability and
                            innovation. Everyone plays a role in driving the
                            institution toward achieving its shared goals and
                            vision.
                          </p>
                        </article>
                        <article style={{ "--slist": "#f5b461, #f07124" }}>
                          <p>
                            Student well-being is at the heart of the school’s
                            priorities. Providing a safe and inclusive
                            environment ensures that students feel valued and
                            supported. Programs focusing on mental health and
                            personal growth are actively implemented.
                            Extracurricular activities complement academics to
                            foster balanced development.
                          </p>
                        </article>
                        <article style={{ "--slist": "#6e9ef1, #3556af" }}>
                          <p>
                            Technology and innovation are at the core of modern
                            education. The school integrates cutting-edge tools
                            to make learning engaging and efficient. Digital
                            resources, interactive methods, and virtual
                            collaboration tools prepare students for future
                            challenges. Technological fluency is a vital part of
                            their journey.
                          </p>
                        </article>
                        <article style={{ "--slist": "#ff8f8f, #e64141" }}>
                          <p>
                            Continuous feedback and reflection systems are vital
                            for growth. Both students and staff benefit from
                            constructive evaluations, enabling improvements at
                            every level. Open communication and regular
                            assessments create a culture of transparency. This
                            culture of reflection drives the school’s commitment
                            to excellence.
                          </p>
                        </article>
                        <article style={{ "--slist": "#89c997, #358853" }}>
                          <p>
                            Inclusivity in education is a cornerstone of the
                            school’s philosophy. Embracing diversity ensures
                            that every student feels seen, heard, and empowered.
                            Equal opportunities are provided, fostering a
                            culture of respect and collaboration. A focus on
                            equity bridges gaps and ensures success for all.
                          </p>
                        </article>
                        <article style={{ "--slist": "#b09fea, #6849c9" }}>
                          <p>
                            Efficient resource utilization is a key factor in
                            the school’s success. From managing physical
                            infrastructure to optimizing staff capabilities,
                            every resource is used strategically. Creative
                            problem-solving and effective planning maximize
                            outcomes. This ensures the delivery of high-quality
                            education to all students.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Experts</h6>
              <h2 className="section__title">Our Members</h2>
            </Col>
            <OurToppers />
          </Row>
        </Container>
      </section> */}
      </Helmet>
    </>
  );
};

export default About;

import React, { useState, useEffect } from "react";
import "lightgallery.js/dist/css/lightgallery.css";
import axios from "axios";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import Common_URL from ".././Config";
const PhotoItem = ({ image, thumb, group }) => (
  <div class="col-md-3">
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: "100%" }} />
      {/* <div class="caption">
        <h4>Header</h4>
        <p>Sub Heading</p>
      </div> */}
    </LightgalleryItem>
  </div>
);
function Gallery() {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Common_URL + "&contenttype=gallery");
        console.log(response);

        const data = await response.json();
        console.log(data);
        setGallery(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const baseUrl = "https://uservw.online/webapi";
  return (
    <div>
      <Helmet title="Gallery">
        <CommonSection title="Gallery" />

        <div class="container my-4">
          <LightgalleryProvider>
            {/* <div class="divider div-transparent con"></div> */}

            <section className="main_gallery">
              <div class="row mb-4">
                {gallery.map((content, index) => (
                  <PhotoItem
                    key={index}
                    image={baseUrl + content.imageurl}
                    group="group2"
                  />
                ))}
              </div>
            </section>
          </LightgalleryProvider>
        </div>
      </Helmet>
    </div>
  );
}

export default Gallery;

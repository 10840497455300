import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toast } from "bootstrap/dist/js/bootstrap.esm.min.js";
import GalleryDelete from "./GalleryDelete";
import SliderDelete from "./SliderDelete";
import MpdConfig from "./MpdConfig";
import FacilitiesConfig from "./FacilitiesConfig";
import AboutUsConfig from "./AboutUsConfig";
import UpcomingNews from "./UpcomingNews";
import FeeStrConfig from "./FeeStrConfig";
import SyllabusConfig from "./SyllabusConfig";
import ExamNoticeConfig from "./ExamNoticeConfig";
import DirectorConfig from "./DirectorConfig";
import PrincipalConfig from "./PrincipalConfig";
import AcademicCalenderConfig from "./AcademicCalenderConfig";
import LinkidineConfig from "./LinkidineConfig";
import WhatsappConifg from "./WhatsappConifg";
import FacebookConfig from "./FacebookConfig";
import TwitterConfig from "./TwitterConfig";
import YoutubeConfig from "./YoutubeConfig";
import MapLocationConfig from "./MapLocationConfig";
import BannerAllertAdmin from "./BannerAllertAdmin";
import { SketchPicker } from 'react-color';
import YoutubeAdminClassConfig from "./YoutubeAdminClassConfig";
import Curriculam from "../../components/UI/Curriculam";
import CurriculamConfig from "./CurriculamConfig";
import BirthdayCleb from "../../components/UI/BirthdayCleb";
import BirthdayClebConfig from "./BirthdayClebConfig";
import HolidayHomeWorkConfig from "./HolidayHomeWorkConfig";

function AdminMainLayout(props) {
  const [post, setPost] = useState({
    id: "",
    startdate: "",
    enddate: "",
    heading: "",
    subheading: "",
    content: "",
    contenturl: "",
    pdfurl: "",
    contenttype: "",
    schoolid: "goldenera",
    imageurl: [],
    active: false,
  });
  const [selectedPage, setSelectedPage] = useState('');
  const [selectedTab, setSelectedTab] = useState("home");
  const [submitMessage, setSubmitMessage] = useState("");
  const [contentTypeError, setContentTypeError] = useState(false);
  const [existingData, setExistingData] = useState(null);
  const [sharedColor, setSharedColor] = useState('#000000'); // Default color
   const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPost({ ...post, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setPost({ ...post, [name]: checked });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const selectedFiles = Array.from(files);

    // Check if the number of files exceeds 5
    if (selectedFiles.length > 5) {
      alert("You can upload a maximum of 5 images at a time.");
      event.target.value = "";
      return;
    }

    // Validate total file sizes
    const totalSize = selectedFiles.reduce((total, file) => total + file.size, 0);
    if (totalSize > 10 * 1024 * 1024) {
      alert("The total size of selected files exceeds 10 MB.");
      event.target.value = "";
      return;
    }

    // Validate file sizes
    const invalidFiles = selectedFiles.filter((file) => file.size > 1 * 1024 * 1024);
    if (invalidFiles.length > 0) {
      const invalidFileNames = invalidFiles.map((file) => file.name).join(", ");
      alert(`The following files are larger than 1 MB: ${invalidFileNames}`);
      event.target.value = "";
      return;
    }

    // Check for duplicate file names
    const existingFiles = post.imageurl.map((file) => file.name);
    const duplicateFiles = selectedFiles.filter((file) => existingFiles.includes(file.name));

    if (duplicateFiles.length > 0) {
      const duplicateFileNames = duplicateFiles.map((file) => file.name).join(", ");
      alert(`The following files already exist: ${duplicateFileNames}. Please choose different files.`);
      event.target.value = "";
      return;
    }

    // If validation passes, update the state
    setPost({ ...post, [name]: [...post[name], ...selectedFiles] });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (const key in post) {
      if (Array.isArray(post[key])) {
        post[key].forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, post[key]);
      }
    }

    try {
      await axios.post("https://uservw.online/webapi/contentlist/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSuccessMessage("Submission successful!");

      setPost({
        id: "",
        startdate: "",
        enddate: "",
        heading: "",
        subheading: "",
        content: "",
        contenturl: "",
        pdfurl: "",
        contenttype: "",
        schoolid: "goldenera",
        imageurl: [],
        active: false,
      });
      document.querySelector('input[name="imageurl"]').value = ""; // Clear the file input field
      // setShowSuccessToast(true);
    } catch (error) {
      // setShowErrorToast(true);
      let errorMessage = "Error making request";

      if (error.response) {
        errorMessage = `Error: ${error.response.data?.message || error.response.statusText}`;
      } else if (error.request) {
        errorMessage = "No response received from the server.";
      } else {
        errorMessage = `Error: ${error.message}`;
      }
      setSubmitMessage(errorMessage);
    }
  };

  useEffect(() => {
    if (submitMessage) {
      const liveToast = new Toast(document.getElementById("liveToast"));
      liveToast.show();
      setTimeout(() => {
        liveToast.hide();
        setSubmitMessage("");
      }, 5000);
    }
  }, [submitMessage]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    
    const contentTypeMap = {
      "announcement": "announcement",
      "banner": "other3",
      "gallery": "gallery",
      "holiday-homework": "other2",
      "slider": "slider",
      "event": "event",
      "facilities": "facilities",
      "aboutus": "aboutus",
      "upcoming-news": "upcoming news",
      "feestructure": "feestructure",
      "syllabus": "syllabus",
      "exam-notice": "other1",
      "director": "director",
      "principal": "principal",
      "academic-calender": "academic calender",
      "social-media": "social media",
      "youtubeclass": "youtube",
      "curriculam":"mandetory",
      "birthday-celebration":"event"
      // same as name as tab content : same as contenttype
    };
  
    // Update the post state based on the selected tab
    setPost(prevPost => ({
      ...prevPost,
      contenttype: contentTypeMap[tab],
      heading: tab === "youtubeclass" ? "youtubeclass" : prevPost.heading !== "youtubeclass" ? prevPost.heading : "", // Keep previous heading or clear if it was "youtubeclass"
    }));
  };
  
  

  const showAddDataButton = () => {
    return selectedTab !== "home" && ["event", "youtube", "logo", "announcement", "other1", "other2", "other3", "gallery", "slider", "facilities", "aboutus", "upcoming news", "feestructure", "syllabus", "director", "principal", "academic calender", "social media", "mandetory"].includes(post.contenttype);
  };

  const handleCheckboxChangeforpage = (event) => {
    const { value } = event.target;
    setSelectedPage(value);
    
    // Update the input field value based on the selected checkbox
    handleInputChange({ target: { name: 'contenturl', value } });
  };
  const handleColorChange = (color) => {
    const newColor = color.hex; // Get the hex value from the color object
    setSharedColor(newColor);
    setPost({ ...post, contenturl: newColor }); // Update contenturl with the shared color
  };

  const handleRadioChange = (event) => {
    const { value } = event.target;
    setPost({ ...post, contenturl: value }); // Update contenturl based on selected radio button
  };

 useEffect(() => {
    if (showSuccessToast || showErrorToast) {
      const timeout = setTimeout(() => {
        setShowSuccessToast(false);
        setShowErrorToast(false);
      }, 3000); // Hide the toast after 5 seconds

      return () => clearTimeout(timeout);
    }
  }, [showSuccessToast, showErrorToast]);

  return (
    <>
      <section className="adj_sec Admin">
        <div className="d-flex justify-content-center">
          {showAddDataButton() && (
            <button
              type="button"
              className="custom__button a"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalnew"
            >
              Add Data
            </button>
          )}
        </div>
        <form onSubmit={handleSubmit} className="border-0">
          <div className="modal fade modal-lg adminlayout" id="exampleModalnew" tabIndex="-1" aria-labelledby="exampleModalnewlLabel" aria-hidden="true" style={{ zoom: "0.85" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalnewlLabel">
                    Add {post.contenttype} Data
                  </h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <fieldset>
                    <div className="form-group d-flex align-items-center my-2">
                    {selectedTab === "social-media" && (
                      <label htmlFor="contenttype" className="col-md-3 form-label">Content Type</label>
                    )}
                      <div className="col-md-9">
                        <select
                          name="contenttype"
                          onChange={handleInputChange}
                          value={post.contenttype}
                          className={`form-control clear-input ${contentTypeError ? "is-invalid" : post.contenttype ? "is-valid" : ""} ${selectedTab !== "social-media" ? "d-none" : ""}`}
                            disabled={selectedTab === "social-media" ? false : true}
                          >
                          {selectedTab === "social-media" ? (
                          <>
                            <option value="" selected="">--------</option>
                            <option value="facebook">Facebook</option>
                            <option value="whatsapp">Whatsapp</option>
                            <option value="linkedin">Linkedin</option>
                            <option value="youtube">Youtube</option>
                            <option value="twitter">Twitter</option>
                          </>
                          ) : (
                          <>
                          <option value="" selected="">--------</option>
                          <option value="upcoming news">Upcoming news</option>
                          <option value="announcement">Announcement</option>
                          <option value="slider">Slider</option>
                          <option value="event">Birthday Celebration</option>
                          <option value="gallery">Gallery</option>
                          <option value="principal">Principal</option>
                          <option value="director">Director</option>
                          <option value="youtube">Youtube</option>
                          <option value="facebook">Facebook</option>
                          <option value="instagram">Instagram</option>
                          <option value="twitter">Twitter</option>
                          <option value="linkedin">Linkedin</option>
                          <option value="whatsapp">Whatsapp</option>
                          <option value="clalender">Clalender</option>
                          <option value="syllabus">Syllabus</option>
                          <option value="feestructure">Fee structure</option>
                          <option value="toppers">Toppers</option>
                          <option value="alumini">Alumini</option>
                          <option value="facilities">Facilities</option>
                          <option value="mandetory">Mandetory</option>
                          <option value="aboutus">About Us</option>
                          <option value="logo">Logo</option>
                          <option value="download">Download</option>
                          <option value="others">Others</option>
                          <option value="other1">Exam Notice</option>
                          <option value="other2">Holiday Homework</option>
                          <option value="other3">Admission Banner</option>
                          </>
                        )}
                        </select>
                        {contentTypeError && <div className="invalid-feedback">Please select a content type</div>}
                      </div>
                    </div>
                     {/* Radio buttons shown based on birthday contenttype value */}
        {post.contenttype === "event" && (
           <div class="form-group d-flex align-items-center my-2 ">
                        <label className="col-md-3  form-label">Data For Birthday </label>
          <div className="col-md-9">
            <label>
              <input type="radio" value="Top Section" checked={post.contenturl === "Top Section"} onChange={handleRadioChange} />
              Top Section
            </label>
            <label>
              <input type="radio" value="Video Section" checked={post.contenturl === "Video Section"} onChange={handleRadioChange} />
              Video Section
            </label>
            <label>
              <input type="radio" value="Gallery Section" checked={post.contenturl === "Gallery Section"} onChange={handleRadioChange} />
              Gallery Section
            </label>
            <label>
              <input type="radio" value="Blog Section" checked={post.contenturl === "Blog Section"} onChange={handleRadioChange} />
              Blog Section
            </label>
          </div>
          </div>
        )}



                    <div class="form-group d-flex align-items-center my-2 ">
                      <label class="col-md-3 control-label ">Startdate</label>

                      <div class="col-md-9">
                        <input
                          name="startdate"
                          class="form-control clear-input"
                          type="date"
                          value={post.startdate}
                          onChange={handleInputChange}
                          disabled={
                            post.contenttype === "gallery" ||
                            post.contenttype === "facebook" ||
                            post.contenttype === "slider" ||
                            post.contenttype === "principal" ||
                            post.contenttype === "director" ||
                            post.contenttype === "facebook" ||
                            post.contenttype === "youtube" ||
                            post.contenttype === "instagram" ||
                            post.contenttype === "twitter" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "whatsapp" ||
                            post.contenttype === "facilities" ||
                            post.contenttype === "mandetory" ||
                            post.contenttype === "aboutus" ||
                            post.contenttype === "logo" ||
                            post.contenttype === ""
                          }
                        />
                      </div>
                    </div>

                    <div class="form-group d-flex align-items-center my-2 ">
                      <label class="col-md-3 control-label ">Enddate</label>

                      <div class="col-md-9">
                        <input
                          name="enddate"
                          class="form-control clear-input"
                          type="date"
                          value={post.enddate}
                          onChange={handleInputChange}
                          disabled={
                            post.contenttype === "gallery" ||
                            post.contenttype === "facebook" ||
                            post.contenttype === "slider" ||
                            post.contenttype === "principal" ||
                            post.contenttype === "director" ||
                            post.contenttype === "facebook" ||
                            post.contenttype === "youtube" ||
                            post.contenttype === "instagram" ||
                            post.contenttype === "twitter" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "whatsapp" ||
                            post.contenttype === "toppers" ||
                            post.contenttype === "facilities" ||
                            post.contenttype === "mandetory" ||
                            post.contenttype === "aboutus" ||
                            post.contenttype === "logo" ||
                            post.contenttype === ""
                          }
                        />
                      </div>
                    </div>

                    <div class="form-group d-flex align-items-center my-2 ">
                    
                    <label class="col-md-3 control-label ">
                      {post.contenttype === "feestructure" || post.contenttype === "syllabus" ? "Class Name" : "Heading"}
                    </label>
                      <div class="col-md-9">
                        <input
                          name="heading"
                          class="form-control clear-input"
                          type="text"
                          value={post.heading}
                          onChange={handleInputChange}
                          disabled={
                            post.contenttype === "gallery" ||
                            post.contenttype === "" ||
                            existingData
                          }
                        />
                      </div>
                    </div>

                    <div class="form-group d-flex align-items-center my-2 ">
                     
                    <label class="col-md-3 control-label ">
                      {post.contenttype === "director" || post.contenttype === "principal" ? "Signature" : "Subheading"}
                    </label>


                      <div class="col-md-9">
                        <input
                          name="subheading"
                          class="form-control clear-input"
                          type="text"
                          value={post.subheading}
                          onChange={handleInputChange}
                          disabled={
                            post.contenttype === "gallery" ||
                            post.contenttype === "facebook" ||
                            post.contenttype === "youtube" ||
                            post.contenttype === "syllabus" ||
                            post.contenttype === "instagram" ||
                            post.contenttype === "twitter" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "whatsapp" ||
                            post.contenttype === "clalender" ||
                            post.contenttype === "" ||
                            existingData
                          }
                        />
                      </div>
                    </div>

                    <div class="form-group d-flex align-items-center my-2 ">
                      <label class="col-md-3 control-label ">Content</label>

                      <div class="col-md-9">
                        <input
                          name="content"
                          class="form-control clear-input"
                          type="text"
                          value={post.content}
                          onChange={handleInputChange}
                          disabled={
                            post.contenttype === "gallery" ||
                            post.contenttype === "facebook" ||
                            post.contenttype === "youtube" ||
                            post.contenttype === "instagram" ||
                            post.contenttype === "syllabus" ||
                            post.contenttype === "twitter" ||
                            post.contenttype === "clalender" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "whatsapp" ||
                            post.contenttype === "clalender" ||
                            post.contenttype === "other1" ||
                            post.contenttype === "logo" ||
                            post.contenttype === "" ||
                            existingData
                          }
                        />
                      </div>
                    </div>

                  
                         
                    {post.contenttype === "slider" && (
                      <>
                      <div class="form-group d-flex align-items-center my-2 ">
                        <label className="col-md-3  form-label">Select Color <br/> for Slider Text</label>
                       <div className="col-md-9">
                        <SketchPicker
                            color={sharedColor}
                            onChangeComplete={handleColorChange}
                          />
                       </div>
                       </div>
                      </>
                    )}
                     {post.contenttype !== "slider" && (
                    <div className="form-group d-flex align-items-center my-2">
                      <label className="col-md-3 control-label">
                        {selectedTab === "youtubeclass" // Check if the selected tab is youtubeclass
                          ? "YouTube Video ID"
                          : post.contenttype === "facebook" ||
                            post.contenttype === "instagram" ||
                            post.contenttype === "twitter" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "whatsapp"
                          ? `${post.contenttype} Url`
                          : post.contenttype === "aboutus"
                          ? "Content for"
                          : "Contenturl"}
                      </label>


                      <div className="col-md-9">
                        {post.contenttype === "aboutus" ? (
                           <div style={{ display: 'flex', gap: '10px', width:"100%" }}>
                            <label className="d-flex w-100">
                              <input
                                type="radio"
                                value="Homepage"
                                checked={selectedPage === "Homepage"}
                                onChange={handleCheckboxChangeforpage}
                                style={{width:"40px"}}
                              />
                              Homepage
                            </label>
                            <label className="d-flex w-100" style={{whiteSpace:"nowrap"}}>
                              <input
                                type="radio"
                                value="About Us Page"
                                checked={selectedPage === "About Us Page"}
                                onChange={handleCheckboxChangeforpage}
                                style={{width:"40px"}}
                              />
                              About Us Page
                            </label>
                          </div>
                        ) : (
                          <input
                            name="contenturl"
                            className="form-control clear-input"
                            type="text"
                            value={post.contenturl}
                            onChange={handleInputChange}
                            disabled={
                              post.contenttype === "gallery" ||
                              post.contenttype === "announcement" ||
                              post.contenttype === "syllabus" ||
                              post.contenttype === "principal" ||
                              post.contenttype === "director" ||
                              post.contenttype === "calendar" ||
                              post.contenttype === "feestructure" ||
                              post.contenttype === "toppers" ||
                              post.contenttype === "alumni" ||
                              post.contenttype === "facilities" ||
                              post.contenttype === "other1" ||
                              post.contenttype === "other2" ||
                              post.contenttype === "logo" ||
                              post.contenttype === "mandatory" ||
                              post.contenttype === ""
                            }
                          />
                        )}
                      </div>
                    </div>
                    )}
                    <div class="form-group d-flex align-items-center my-2 ">
                     
                      <label class="col-md-3 control-label ">
                      {post.contenttype === "feestructure" || post.contenttype === "syllabus" ? `${post.contenttype} Pdf` : "Pdfurl"}
                    </label>
                      <div class="col-md-9">
                        <input
                          className="form-control clear-input"
                          name="pdfurl"
                          type="file"
                          onChange={handleFileChange}
                          disabled={
                            post.contenttype === "gallery" ||
                            post.contenttype === "facebook" ||
                            post.contenttype === "upcoming news" ||
                            post.contenttype === "announcement" ||
                            post.contenttype === "slider" ||
                            post.contenttype === "event" ||
                            post.contenttype === "principal" ||
                            post.contenttype === "director" ||
                            post.contenttype === "facebook" ||
                            post.contenttype === "youtube" ||
                            post.contenttype === "instagram" ||
                            post.contenttype === "twitter" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "whatsapp" ||
                            post.contenttype === "toppers" ||
                            post.contenttype === "alumini" ||
                            post.contenttype === "facilities" ||
                            post.contenttype === "clalender" ||
                            post.contenttype === "aboutus" ||
                            post.contenttype === "mandetory" ||
                            post.contenttype === "others" ||
                            post.contenttype === "other1" ||
                            post.contenttype === "other3" ||
                            post.contenttype === "logo" ||
                            post.contenttype === ""
                          }
                        />
                      </div>
                    </div>
                   
                    <div class="form-group d-flex align-items-center my-2 d-none">
                      <label class="col-md-3 control-label ">Schoolid</label>

                      <div class="col-md-9">
                        <input
                          name="schoolid"
                          class="form-control"
                          type="text"
                          // value={post.schoolid}
                          value="goldenera"
                          onChange={handleInputChange}
                          disabled
                        />
                      </div>
                    </div>

                    <div class="form-group d-flex align-items-center my-2 ">
                      <label class="col-md-3 control-label ">Imageurl</label>

                      <div class="col-md-9">
                        <input
                          name="imageurl"
                          className="form-control clear-input"
                          type="file"
                          onChange={handleFileChange}
                          multiple
                          disabled={
                            post.contenttype === "facebook" ||
                            post.contenttype === "facebook" ||
                            post.contenttype === "youtube" ||
                            post.contenttype === "instagram" ||
                            post.contenttype === "twitter" ||
                            post.contenttype === "syllabus" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "linkedin" ||
                            post.contenttype === "whatsapp" ||
                            post.contenttype === "other2" ||
                            post.contenttype === "upcoming news" ||
                            post.contenttype === "feestructure" ||
                            post.contenttype === "" ||
                            existingData
                          }
                        />
                      </div>
                    </div>

                    <div class="form-group d-flex align-items-center my-2 horizontal-checkbox ">
                      <label class="col-md-3 control-label ">Active</label>

                      <div class="col-md-9">
                        <input
                          type="checkbox"
                          name="active"
                          // value="true"
                          value={post.active}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                    </div>

                    
                  
                  </fieldset>
                  {successMessage && <div className="alert alert-success">{successMessage}</div>}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="submit" className="btn btn-success">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className="main_layout my-5">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            {/* ##############   ADD BUTTON HERE  ################# */}
            {["home", "banner", "holiday-homework", "gallery", "slider", "facilities", "aboutus", "upcoming-news", "feestructure", "syllabus", "exam-notice", "director", "principal", "academic-calender", "social-media", "youtubeclass", "curriculam", "birthday-celebration"].map((tab) => (
              <li className="nav-item" role="presentation" key={tab}>
                <button
                  className={`nav-link ${selectedTab === tab ? "active" : ""}`}
                  id={`pills-${tab}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-${tab}`}
                  type="button"
                  role="tab"
                  aria-controls={`pills-${tab}`}
                  aria-selected={selectedTab === tab}
                  onClick={() => handleTabChange(tab)}
                >
                  {tab.replace("-", " ").toUpperCase()}
                </button>
              </li>
            ))}
          </ul>

          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">Home Content</div>
            <div className="tab-pane fade" id="pills-banner" role="tabpanel" aria-labelledby="pills-banner-tab"><BannerAllertAdmin /></div>
            <div className="tab-pane fade" id="pills-holiday-homework" role="tabpanel" aria-labelledby="pills-holiday-homework-tab"><HolidayHomeWorkConfig /></div>
            <div className="tab-pane fade" id="pills-gallery" role="tabpanel" aria-labelledby="pills-gallery-tab"><GalleryDelete /></div>
            <div className="tab-pane fade" id="pills-slider" role="tabpanel" aria-labelledby="pills-slider-tab"><SliderDelete /></div>
            <div className="tab-pane fade" id="pills-mpd" role="tabpanel" aria-labelledby="pills-mpd-tab"><MpdConfig /></div>
            <div className="tab-pane fade" id="pills-facilities" role="tabpanel" aria-labelledby="pills-facilities-tab"><FacilitiesConfig /></div>
            <div className="tab-pane fade" id="pills-aboutus" role="tabpanel" aria-labelledby="pills-aboutus-tab"><AboutUsConfig /></div>
            <div className="tab-pane fade" id="pills-upcoming-news" role="tabpanel" aria-labelledby="pills-upcoming-news-tab"><UpcomingNews /></div>
            <div className="tab-pane fade" id="pills-feestructure" role="tabpanel" aria-labelledby="pills-feestructure-tab"><FeeStrConfig /></div>
            <div className="tab-pane fade" id="pills-syllabus" role="tabpanel" aria-labelledby="pills-syllabus-tab"><SyllabusConfig /></div>
            <div className="tab-pane fade" id="pills-exam-notice" role="tabpanel" aria-labelledby="pills-exam-notice-tab"><ExamNoticeConfig /></div>
            <div className="tab-pane fade" id="pills-director" role="tabpanel" aria-labelledby="pills-director-tab"><DirectorConfig /></div>
            <div className="tab-pane fade" id="pills-principal" role="tabpanel" aria-labelledby="pills-principal-tab"><PrincipalConfig /></div>
            <div className="tab-pane fade" id="pills-academic-calender" role="tabpanel" aria-labelledby="pills-academic-calender-tab"><AcademicCalenderConfig /></div>
            <div className="tab-pane fade" id="pills-youtubeclass" role="tabpanel" aria-labelledby="pills-youtubeclass-tab"><YoutubeAdminClassConfig /></div>
            <div className="tab-pane fade" id="pills-curriculam" role="tabpanel" aria-labelledby="pills-curriculam-tab"><CurriculamConfig /></div>
            <div className="tab-pane fade" id="pills-birthday-celebration" role="tabpanel" aria-labelledby="pills-birthday-celebration-tab"><BirthdayClebConfig/></div>
            
            <div
              class="tab-pane fade"
              id="pills-social-media"
              role="tabpanel"
              aria-labelledby="pills-social-media-tab"
            >
              <h2 class="admin_heading">Social Media Link</h2>

              <WhatsappConifg />
              <FacebookConfig />
              <TwitterConfig />
              <YoutubeConfig />
              <LinkidineConfig />
            </div>
          </div>
        </div>
      </section>
       {/* Bootstrap Toasts */}
      {/* Success Toast */}

      <div
        aria-live="polite"
        aria-atomic="true"
        class="d-flex justify-content-center align-items-center w-100"
      >
        <div
          id="liveToast"
          className={`toast bg-success ${showSuccessToast ? "show" : "hide"}`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <strong className="me-auto">Submission Status</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => setShowSuccessToast(false)}
            ></button>
          </div>
          <div className="toast-body text-white">Operation successful</div>
        </div>
      </div>

      {/* Error Toast */}
      <div
        className={`toast align-items-center text-white bg-danger ${
          showErrorToast ? "show" : ""
        }`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ position: "fixed", top: "10px", right: "10px", zIndex: 9999 }}
      >
        <div className="d-flex">
          <div className="toast-body">Error updating/deleting data</div>
          <button
            type="button"
            className="btn-close me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={() => setShowErrorToast(false)}
          ></button>
        </div>
      </div>
    </>
  );
}

export default AdminMainLayout;

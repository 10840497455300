import React, { Fragment, useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Routers from "../../routers/Routers";
import BannerAllert  from "../../pages/Admin/BannerAllert";
const Layout = () => {
 
  return (
    <Fragment>
      <Header />
      <div>
        <Routers />
        
      </div>
   
      <Footer />
      <BannerAllert />
    </Fragment>
  );
};

export default Layout;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../styles/about-section.css";
import Common_URL from "../../Config";
import CommonSection from "./CommonSection";

const Curriculam = ({ aboutClass }) => {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Common_URL + "&contenttype=mandetory");
        setContents(response.data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };
    fetchData();
  }, []);

  const renderContents = () => {
    const baseUrl = "https://uservw.online/webapi";

    return contents
      .filter((content) => content.contenturl === "")
      .map((content, index) => (
        <div key={index} className={`col-md-4 curriculam`}>
        <article  class="card">
          <header class="card__thumb">
            <img src={baseUrl + content.imageurl} />
          </header>
          <div class="card__date">
            <span class="card__date__day">2025-26</span>
            <span class="card__date__month">Session</span>
          </div>
          <div class="card__body">
            <div class="card__category">
              <a href="#">Class time : 8:00 AM - 02:00 PM</a>
            </div>
            <div class="card__title">
              <a href="#">{content.heading}</a>
            </div>
            <div class="card__subtitle d-none">{content.subheading}</div>
            <p class="sett">{content.content}</p>
          </div>
          <footer class="card__footer">
            <span class="solar_btn">
              Class Size <br /> 30-40{" "}
            </span>
            <span class="solar_btn">
              Year's Old <br /> 5-6
            </span>
            <span class="solar_btn">
              Tution Fee <br /> ₹ 1500.00
            </span>
          </footer>
        </article>
        </div>
      ));
  };

  return (
    <>
      <CommonSection title="Curriculam" />
      <section
        className="about__section"
        style={
          aboutClass === "aboutPage"
            ? { marginTop: "0px" }
            : { marginTop: "-120px" }
        }
      >
        <div className="container">
          <div className="row mt-5">
           {renderContents()}
          </div>
        </div>
      </section>
    </>
  );
};

export default Curriculam;

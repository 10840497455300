import React from "react";
import Slider from "react-slick";

import "../../styles/testimonial.css";
import classs from "../../assets/images/classes/classs.jpg"; // Example local image import
import class1 from "../../assets/images/classes/class1.jpg";
import class7 from "../../assets/images/classes/class7.jpg";
import class2 from "../../assets/images/classes/class2.jpg";
const CulturalEvents = () => {
  const culturalEventsData = [
    {
      src: classs,
      heading: "To Think Creatively and Create",
      subheading: "Subheading for Event 1",
    },
    {
      src: class1,
      heading: "To Feel Fine and to Understand Emotions",
      subheading: "Subheading for Event 2",
    },
    {
      src: class2,
      heading: "To be Independent and Active",
      subheading: "Subheading for Event 3",
    },
    {
      src: class7,
      heading: "To Apply Knowledge in Life",
      subheading: "Subheading for Event 4",
    },
    {
      src: class2,
      heading: "To be Independent and Active",
      subheading: "Subheading for Event 3",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    swipeToSlide: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {culturalEventsData.map((event, index) => (
          <div key={index} className="testimonial">
            <div className="gap_4">
              <img src={event.src} alt={event.heading} className="img_culture" />
              <h6 className="events_headings">{event.heading}</h6>
              <p className="d-none">{event.subheading}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CulturalEvents;

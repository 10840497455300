import React, { useState, useEffect } from "react";
import "../../styles/become-driver.css";
import Common_URL from "../../Config";
import {
  Container,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import logo from "../../assets/all-images/logo.png";
function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
const baseUrl = "https://uservw.online/webapi";

const TabPanel = (props) => {
  const {
    children,
    value,
    index,
    directorMessages,
    principalMessages,
    handleChange,
    ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3} display="flex">
        {index === 0 && directorMessages && (
          <div className="row">
            <div className="col-md-5 text-center">
              {directorMessages.map((message, idx) => (
                <img
                  key={idx}
                  src={baseUrl + message.imageurl}
                  alt={message.title}
                  className="img-fluid"
                />
              ))}
            </div>
            <div className="col-md-7">
              {directorMessages.map((message, idx) => (
                <div key={idx}>
                  <h2 className="mb-3">{message.heading}</h2>
                  <p>{message.content}</p>
                  <h6 className="d-flex justify-content-end text-danger signature">
                    {message.subheading}
                  </h6>
                </div>
              ))}
            </div>
          </div>
        )}
        {index === 1 && principalMessages && (
          <div className="row">
            <div className="col-md-5 text-center">
              {principalMessages.map((message, idx) => (
                <img
                  key={idx}
                  src={baseUrl + message.imageurl}
                  alt={message.title}
                  className="img-fluid"
                />
              ))}
            </div>
            <div className="col-md-7">
              {principalMessages.map((message, idx) => (
                <div key={idx}>
                <h5 class="solar_center solar_left">{message.heading}</h5>
                  {/* <h2 className="mb-3"></h2> */}
                  <p>{message.content}</p>
                  {/* Add other content here */}
                  <h6 className="d-flex justify-content-end text-danger signature">
                    {message.subheading}
                  </h6>
                </div>
              ))}
            </div>
          </div>
        )}
      </Box>
    </Typography>
  );
};

const BecomeDriverSection = () => {
  const [directorMessages, setDirectorMessages] = useState([]);
  const [principalMessages, setPrincipalMessages] = useState([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    // Fetch director messages
    fetch(Common_URL + "&contenttype=director")
      .then((response) => response.json())
      .then((data) => {
        setDirectorMessages(data);
      })
      .catch((error) => {
        console.error("Error fetching director messages:", error);
      });

    // Fetch principal messages
    fetch(Common_URL + "&contenttype=principal")
      .then((response) => response.json())
      .then((data) => {
        setPrincipalMessages(data);
      })
      .catch((error) => {
        console.error("Error fetching principal messages:", error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <section className="become__driver director">
      <Container>
        {/* <img src={logo} alt="" className="watermark" /> */}
        <div className="row justify-content-center mb-5">
          <div className="col-md-5">
            <AppBar position="static">
              <Tabs value={value} onChange={handleChange} aria-label="tabs">
                <Tab label="Director Message" />
                <Tab label="Principal Message" />
              </Tabs>
            </AppBar>
          </div>
        </div>
        <TabPanel
          value={value}
          index={0}
          directorMessages={directorMessages}
          principalMessages={principalMessages}
        >
          Director Message Content
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          directorMessages={directorMessages}
          principalMessages={principalMessages}
        >
          Principal Message Content
        </TabPanel>
      </Container>
    </section>
  );
};

export default BecomeDriverSection;

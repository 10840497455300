import React, { useState, useEffect } from "react";
import axios from "axios";
import pdf2 from "../../assets/illustration/pdf2.png"; // Import the PDF icon image
import Common_URL from "../../Config";
const HolidayHomeWorkAdmin = () => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const response = await axios.get(
          Common_URL + "&contenttype=other2"
        );
        setAnnouncements(response.data);
      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleDownloadPdf = (pdfUrl, heading) => {
    if (pdfUrl) {
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.target = "_blank";
      link.setAttribute("rel", "noopener noreferrer"); // For security
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(`No PDF available for "${heading}"`);
    }
  };
  const baseUrl = "https://uservw.online/webapi";

  const getYearFromDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  const getSessionText = (startDate, endDate) => {
    if (startDate && endDate) {
      return `${getYearFromDate(startDate)} - ${getYearFromDate(endDate)}`;
    } else {
      return "Session Invalid";
    }
  };

  return (
    <div>
      <div>
        <table className="table">
          <thead>
          <tr>
            <th scope="col">Home Work File</th>
            <th scope="col">Class</th>
            <th scope="col">Subject</th>
            <th scope="col">Details</th>
            <th scope="col">Date</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
            {announcements.map((announcement) => (
              <tr key={announcement.id}>
                
              <th scope="row">
                <img src={pdf2} alt="PDF" width="45" height="40" />
              </th>
              <td>{announcement.heading}</td>
              <td>{announcement.subheading}</td>
              <td>{announcement.content}</td>
              <td>
                {announcement.startdate && announcement.enddate
                  ? `${new Date(announcement.startdate).toLocaleDateString()} - ${new Date(announcement.enddate).toLocaleDateString()}`
                  : "Date not available"}
              </td>
                <td>
                  {announcement.pdfurl && (
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() =>
                        handleDownloadPdf(
                          baseUrl + announcement.pdfurl,
                          announcement.heading
                        )
                      }
                    >
                      Download
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HolidayHomeWorkAdmin;

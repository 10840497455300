import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/BannerAlert.css";
import Common_URL from "../../Config";

class BannerAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [], // Store fetched image data
      hiddenBanners: [], // Track hidden banners by index
    };
  }

  componentDidMount() {
    fetch(Common_URL + "&contenttype=other3")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((images) => {
        this.setState({ images });
      })
      .catch((error) => console.error("Error fetching images:", error));
  }

  getYearFromDate = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear(); // Full year
  };

  getLastTwoDigitsOfYear = (dateString) => {
    const year = this.getYearFromDate(dateString);
    return year.toString().slice(-2); // Get last two digits
  };

  getSessionText = (startdate, enddate) => {
    if (startdate && enddate) {
      return `${this.getYearFromDate(
        startdate
      )} - ${this.getLastTwoDigitsOfYear(enddate)}`;
    }
    return "Session Invalid";
  };

  hideBanner = (index) => {
    this.setState((prevState) => ({
      hiddenBanners: [...prevState.hiddenBanners, index],
    }));
  };

  render() {
    const { images, hiddenBanners } = this.state;
    const baseUrl = "https://uservw.online/webapi";

    if (!images || images.length === 0) {
      return <div>Images are not fetched yet!</div>;
    }

    return (
      <div className="alert_banner">
        {images.map((imageData, index) => {
          if (hiddenBanners.includes(index)) {
            return null; // Skip rendering hidden banners
          }

          // Only display the banner if it's active
          if (!imageData.active) {
            return null; // Skip rendering if the banner is not active
          }

          // WhatsApp link
          const whatsappLink = `https://wa.me/${imageData.content}`;

          return (
            <div
              className="banner_outer"
              key={index}
              style={{ display: imageData.active ? "block" : "none" }}
            >
              <i
                className="cancel_icon fas fa-times"
                onClick={() => this.hideBanner(index)}
                style={{cursor:"pointer"}}
              ></i>
              <div className="bannerlogo">
                <img
                  className="img-fluid"
                  src={baseUrl + imageData.imageurl}
                  alt={`Banner image ${index + 1}`}
                />
              </div>
              <div className="session_year p-2">
                {imageData.startdate && imageData.enddate && (
                  <h2>
                    {this.getSessionText(
                      imageData.startdate,
                      imageData.enddate
                    )}
                  </h2>
                )}
              </div>
              <div className="contact">
                <p>{imageData.content}</p>
                {/* Display WhatsApp link */}
                
              </div>

              <div className="banner_points admission_b">
                <article style={{ "--slist": "#ebac79, #d65b56" }}>
                  <h3>Quality Education</h3>
                </article>
                <article style={{ "--slist": "#90cbb7, #2fb1a9" }}>
                  <h3>Interactive Atmosphere</h3>
                </article>
                <article style={{ "--slist": "#8a7876, #32201c" }}>
                  <h3>Good Infrastructure</h3>
                </article>
                <article style={{ "--slist": "#a6c869, #37a65a" }}>
                  <h3>Day Care</h3>
                </article>
              </div>

              <a href={whatsappLink} target="_blank" className="btn-whatsapp-pulse btn-whatsapp-pulse-border">
                <i className="fab fa-whatsapp"></i>
              </a>
            </div>
          );
        })}
      </div>
    );
  }
}

export default BannerAlert;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../styles/about-section.css";
import Common_URL from "../../Config";

const AboutUsPage = ({ aboutClass }) => {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Common_URL + "&contenttype=aboutus"); // Fetch all relevant content
        setContents(response.data);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };
    fetchData();
  }, []);

  const renderContents = () => {
    const baseUrl = "https://uservw.online/webapi";

    return contents
      .filter(content => content.contenturl === "About Us Page") // Filter contents where contenturl is "Homepage"
      .map((content, index) => (
        <div key={index} className="row mt-5">
          <div className={`col-md-7 setcss order-md-${content.active ? 1 : 2}`}>
            <div className="solar_about_detail">
              <h5 className="solar_center solar_left">{content.heading}</h5>
              <h2 className="text_span">{content.subheading}</h2>
              <p>{content.content}</p>
              {/* <Link to="/about" className="solar_btn my-4">
                View More
              </Link> */}
            </div>
          </div>
          <div className={`col-md-5 order-md-${content.active ? 2 : 1}`}>
            <div className="about_us_image">
              <img
                className="img_full"
                src={baseUrl + content.imageurl}
                alt="image"
              />
            </div>
          </div>
        </div>
      ));
  };

  return (
    <>
      <section
        className="about__section"
        style={
          aboutClass === "aboutPage"
            ? { marginTop: "0px" }
            : { marginTop: "-120px" }
        }
      >
        <div className="container">{renderContents()}</div>
      </section>
    </>
  );
};

export default AboutUsPage;
